import './App.css';

function App() {
  return (
    <div className="background">
        <div className="container glassmorphism">
            <div className="introduction">
              <h1 className='title'>
                Hello,<br />
                This is <a href="https://www.linkedin.com/in/paito/" className='shine'>Paito Anderson</a><br />
                Partner at <a href="https://appnouveau.com/" className='shine'>App Nouveau Canada</a>
              </h1>
            </div>
        </div>

        <div className="contact-box glassmorphism">
          <picture>
            <source srcSet="./images/EmailMeQR-dark.png" media="(prefers-color-scheme: dark)" />
            <source srcSet="./images/EmailMeQR.png" media="(prefers-color-scheme: light), (prefers-color-scheme: no-preference)" />
            <img src="./images/EmailMeQR.png" className='contact-qrcode' alt="QR Code" />
          </picture>
          <p className="contact-label">Email Me</p>
        </div>
    </div>
  );
}

export default App;
